import axios from "axios"
const userItem = "userDonnateurIformationsLanfiatech"

const tokenString = localStorage.getItem(userItem);
export const BASE_URL  = 'https://apidons.up.railway.app'
export const API_VULNERABLE = ' https://apivulnerable.up.railway.app/'
const axiosInstance = axios.create({
    baseURL:`${ BASE_URL}/`,
    headers: tokenString?{'Authorization': 'Bearer ' +tokenString}:{}
  });

//  return instance;
//}
export default axiosInstance
